<template>
  <div class="search-box">
    <div class="search-box-content">
      <iconic class="search-box-icon" :name="`${loading ? 'gspinner' : 'search'}`" />
      <input
        class="search-box-input"
        type="text"
        name="search"
        autocomplete="off"
        placeholder="Escriba aquí para buscar"
        @input="input"
        autofocus="on"
        :value="value"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["placeholder", "name", "loading", "value"],
  methods: {
    input: function(e) {
      this.$emit("input", e.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-box {
  padding: 0 0 $mpadding 0;
  &-content {
    position: relative;
    display: flex;
    background-color: rgba(0, 0, 0, 0.05);
    align-items: center;
    margin: 0 $mpadding;
    border-radius: $mpadding/2;
  }
  &-icon {
    position: absolute;
    left: $mpadding;
    pointer-events: none;
  }
  &-input {
    width: 100%;
    padding: $mpadding/2 $mpadding $mpadding/2 $mpadding * 3;
    background-color: rgba(0, 0, 0, 0);
    border: none;
  }
}
</style>
